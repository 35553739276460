.imlp-component-header {
  position: fixed;
  top: 0;
  width: 100%;
}
.top-background-img {
  width: 100%;
  padding-top: 44Px;
  position: relative;
}
.top-background-img img {
  width: 100%;
}
.logo {
  position: relative;
  width: 60px;
  height: 60px;
  top: -30px;
  left: 15px;
  border: 1Px solid #f1f1f1;
  border-radius: 50%;
}
.logo img {
  width: 100%;
  border-radius: 50%;
  display: block;
}
.hospital-name {
  color: #333;
  margin-top: -23px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
  padding: 0 15px;
  line-height: 32px;
}
.basic-info {
  padding: 0 15px;
  text-align: justify;
  font-size: 14px;
  color: #555;
  line-height: 19px;
}
.hospital-address .title {
  font-size: 15px;
  margin: 10px 0;
}
.hospital-address .address-info {
  border-radius: 2Px;
  background-color: #f5f5f5;
  display: inline-block;
  padding: 5px 10px;
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 44Px;
}
.bottom a {
  display: block;
  width: 50%;
  float: left;
  font-size: 16px;
  text-align: center;
  line-height: 44Px;
  text-decoration: none;
  color: #fff;
}
.bottom a.tel {
  background-color: #91c0f8;
}
.bottom a.swt {
  background-color: #62a6f5;
}
